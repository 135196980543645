<!-- 注册界面 -->
<template>
    <div>
        <header>
            <oNav :showAct="true" />
        </header>
        <div class="main">
            <div class="login-box">
                <div class="tab">
                    <!-- <div class="tab-item act">账号登录</div> -->
                    <div class="tab-item">找回密码</div>
                </div>
                <div class="login-form">
                    <!-- <div >
                        <div class="login-item row">
                            <div class="login-icon user"></div>
                            <el-input v-model="form.userName" placeholder="请输入用户名"></el-input>
                        </div>
                        <div class="login-item row">
                            <div class="login-icon password"></div>
                            <el-input v-model="form.userName" placeholder="请输入密码"></el-input>
                        </div>
                        <div class="login-item row yzm">
                            <div class="login-icon check"></div>
                            <el-input v-model="form.userName" placeholder="请输入验证码"></el-input>
                        </div>
                    </div> -->
                    <div>
                        <div class="login-item row" :class="{err:err.phone}">
                            <div class="ismast">*</div>
                            <div class="login-icon user"></div>
                            <el-input v-model="form.mobiles" placeholder="请输入手机号码" @blur="checkPhone"></el-input>
                            <div class="err-text" v-show="err.phone">请输入正确的手机号码</div>
                        </div>
                        <div class="row ">
                            <div class="login-item yzm row" :class="{err:err.code}">
                                <div class="ismast">*</div>
                                <div class="login-icon check"></div>
                                <el-input v-model="form.vericode" placeholder="请输入验证码" style="width:260px" @blur="checkCode"></el-input>
                                <div class="err-text" v-show="err.code">请输入验证码</div>
                            </div>
                            <div class="code">
                                <el-button type="text" @click="getCode" :disabled="codeTime !== 0">{{!codeTime?'获取验证码':`${codeTime}后重新获取`}}</el-button>
                                <!-- <SIdentify :identifyCode="identifyCode"></SIdentify> -->
                            </div>
                        </div> 
                        <div class="login-item row" :class="{err:err.password}">
                            <div class="ismast">*</div>
                            <div class="login-icon password"></div>
                            <el-input type="password" v-model="form.password" placeholder="请设置新密码" @blur="checkPassword"></el-input>
                            <div class="err-text" v-show="err.password">{{err.somePass?'两次密码输入不一致':'请输入密码'}}</div>
                        </div>
                        <div class="login-item row" :class="{err:err.password1}">
                            <div class="ismast">*</div>
                            <div class="login-icon password"></div>
                            <el-input type="password" v-model="form.password1" placeholder="请再次输入新密码" @blur="checkPassword1"></el-input>
                            <div class="err-text" v-show="err.password1">{{err.somePass1?'两次密码输入不一致':'请输入确认密码'}}</div>
                        </div>
                         
                    </div>
                    <div class="submit" @click="submit" v-loading="loading">提 交</div>
                    <div class="signIn"><router-link to="/login">去登录 ></router-link>  </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import oNav from '@/components/nav'
import {SendSms,Verify,Register,reSetPassword} from '@/api/signIn'
export default {
    components:{oNav},
    data(){
        return{
            form:{},
            codeTime:0,
            loading:false,
            err:{phone:false,code:false,password:false,password1:false,name:false,somePass:false,somePass1:false},
        }
    },
    methods:{
        getCode(){
            if(this.checkPhone()){
                const data = {
                    mobiles:this.form.mobiles,
                }
                this.codeTime = 90;
                let oTimer = setInterval(()=>{
                    if(this.codeTime>0){
                        this.codeTime -= 1;
                    }else{
                        clearInterval(oTimer);
                    }
                },1000)
                SendSms(data).then(res=>{
                    console.log(res)
                    this.form.id = res.data.data.id;
                })
            }else{
                this.$message({
                    message:'请输入正确的手机号码',
                    type:'error'
                })
            }
        },
        submit(){
            if(this.checkPhone()&&this.checkCode()&&this.checkPassword()&&this.checkPassword1()){
                if(!this.form.id){
                    this.$message({
                        message:'验证码不正确或已失效',
                        type:'error'
                    })
                    return false;
                }
                const codeData = {
                    id:this.form.id,
                    vericode:this.form.vericode
                }
                this.loading = true;
                Verify(codeData).then(res=>{
                    if(res.data.code == 1){
                        const userData = {
                            id:this.form.id,
                            password:this.form.password,
                        }
                        reSetPassword(userData).then(resUser=>{
                            if(resUser.data.code == 1){
                                this.$message({
                                    message:'重置成功，稍后将跳转到登录页',
                                    type:'success'
                                })
                                this.$router.push({name:'login',params:{account:this.form.mobiles}})
                                this.loading = false;
                            }else{
                                this.$message({
                                    message:resUser.data.msg,
                                    type:'error'
                                })
                                this.loading = false;
                            }
                        }).catch(e=>{
                            this.loading = false;
                        })
                    }else{
                        this.$message({
                            message: res.data.msg || '验证码错误',
                            type:'error'
                        })
                        this.loading = false;
                    }
                }).catch(e=>{
                    this.loading = false;
                })
            }else{
                return false
            }
        },
        checkPhone(value){
            let reg = /^1[3456789]\d{9}$/;
            let isTrue = reg.test(this.form.mobiles)
            if(isTrue){
                this.err.phone = false;
            }else{
                this.err.phone = true;
            }
            return isTrue; 
        },
        checkCode(){
            if(this.form.vericode&&this.form.vericode.trim()){
                this.err.code = false;
                return true
            }else{
                this.err.code = true;
                return false
            }
        },
        checkPassword(){
            if(this.form.password1){
                if(this.form.password === this.form.password1){
                    this.err.password = false;
                    return true
                }else{
                    this.err.somePass = true;
                    this.err.password = true;
                    return false
                }
            }else{
                if(this.form.password&&this.form.password.trim()){
                    this.err.password = false;
                    return true
                }else{
                    this.err.password = true;
                    return false
                }
            }
            
        },
        checkPassword1(){
            if(this.form.password === this.form.password1){
                this.err.password1 = false;
                this.err.somePass1 = false
                return true
            }else{
                this.err.somePass1 = true;
                this.err.password1 = true;
                return false
            }     
        },
        checkNickname(){
            if(this.form.nickName&&this.form.nickName.trim()){
                this.err.name = false;
                return true;
            }else{
                this.err.name = true;
                return false;
            }
        }
    },
    mounted(){
        this.$forceUpdate()
    }
}
</script>
<style scoped>
.err-text{
    position: absolute;
    bottom: -24px;
    font-size: 14px;
    color: #F56C6C;
    left: 100px;
    line-height: 24px;
}
.login-item.err{
    background:  #f1c2c2;
}
.ismast{
    font-size: 24px;
    color: #F56C6C;
    margin-left: 20px;
}
.code{
    line-height: 58px;
    margin-top: 24px;
    cursor: pointer;
    margin-left: 20px;
}
.code >>> .el-button{
    font-size: 20px;    
}
.submit:hover{
    background: #4997f7;
    cursor: pointer;
}
.signIn:hover{
    color: #04489b;
    text-decoration: underline;
    cursor: pointer;
}
.signIn{
    color: #1a80ff;
    text-align: right;
    font-size: 20px;
    line-height: 50px;
    padding-right: 50px;
}
.submit{
    line-height: 58px;
    border-radius: 34px;
    font-size: 24px;
    color: #fff;
    background: #1a80ff;
    text-align: center;
    margin-top: 24px;
}
.login-item >>> .el-input{
    width: 325px;
    font-size: 24px;
    
}
.login-item.yzm >>> .el-input{
    width: 220px;
}
.login-item.yzm {
    width: 300px;
}
.login-item >>> .el-input__inner::placeholder{
    color: #4d98f3;
}
.login-item >>> .el-input__inner{
    color: #1a80ff;
    background-color:transparent;
    border: 0;
}
.login-icon.user{
    background: url(../../public/img/login/icon-user.png) no-repeat center center;
}
.login-icon.password{
    background: url(../../public/img/login/icon-password.png) no-repeat center center;
}
.login-icon.check{
    background: url(../../public/img/login/icon-code.png) no-repeat center center;
}
.login-icon.name{
    background: url(../../public/img/login/icon-name.png) no-repeat center center;
}
.login-icon{
    width: 42px;
    height: 58px;
}
.login-item{
    background: #c8e1ff;
    line-height: 58px;
    border-radius: 34px;
    margin-top: 24px;
    position: relative;
}
.login-form{
    padding: 10px 60px;
}
.tab{
    display: flex;
}
.tab-item.act{
    color: #1a80ff;
    border-bottom: 1px solid #1a80ff;
}
.tab-item{
    /* width: 50%; */
    width: 100%;
    text-align: center;
    line-height: 80px;
    font-size: 24px;
    color: #999;
    border-bottom: 1px solid #ccc;
}
.login-box{
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 580px;
    background: #fff;
    border-radius: 20px;
    padding-bottom: 30px;
}
.main{
    height: calc(100vh - 66px);
    background: url(../../public/img/login/logo-bg.png) no-repeat center center;
    position: relative;
}
</style>